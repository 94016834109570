import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import "firebase/compat/storage";

 
const firebaseConfig = {
  apiKey: "AIzaSyAduHoLvdWrmGBOfwoiSEsRRO_w4m_J_Uo",
  authDomain: "maxthefamilypaynecouk.firebaseapp.com",
  projectId: "maxthefamilypaynecouk",
  storageBucket: "maxthefamilypaynecouk.appspot.com",
  messagingSenderId: "163027617631",
  appId: "1:163027617631:web:f76d5990961aebf41318ea",
  measurementId: "G-199MB35TKR"
};
firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
let firestore = firebase.firestore();
const storage=firebase.storage();
export { auth, firebase, firestore, storage };
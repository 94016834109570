import React, {   useEffect, useState } from "react";
import { firebase, auth } from "./firebase";
import { collection, getDocs, addDoc, query, where} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes, listAll } from 'firebase/storage';
import ReactPlayer from 'react-player'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTrash} from '@fortawesome/free-solid-svg-icons'

import "./App.css"
import "./index.css"


const Mainpage = () => {
  const user = firebase.auth().currentUser;
  const [data, setData] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [images, setImages] = useState();
  const storage = getStorage();


  const [ChildData, setChildData] = useState({
    name: '',
    food: '',
    sender: user.uid,
    // Add more fields here as needed
  });

  const deleteChild = () => {
    const db = firebase.firestore();
    const query = db.collection('invites').where("sender", "==", user.uid);
    query.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.ref points to the document for deletion
        doc.ref.delete().then(() => {
         window.location.reload();
        }).catch((error) => {
          console.error("Error deleting document:", error);
        });
      });
    });
  }

  const uploadFiles = async () => {
    for (let i = 0; i < images.length; i++) {
      const currentDate = Math.floor(Date.now() / 1000)
      const imageRef = ref(storage, `/gallery/${currentDate}${images[i].name}`);

       await uploadBytes(imageRef, images[i])
        .then(() => {
          console.log("success");
          window.location.reload();
        })
        .catch((error) => {
          console.log("error");
        });
    }
  };

  useEffect(() => {

  const getVideoUrls = async() => {
    try {
      const storageRef = ref(storage, 'gallery'); // Replace with your folder path
      const listResult = await listAll(storageRef);
  
      const videoUrls = await Promise.all(
        listResult.items.map(async (videoRef) => {
        
          // Check if the item is an image (optional)
          if (isVideo(videoRef.name)) { // Implement your logic to check image format
            
            const downloadURL = await getDownloadURL(videoRef);
            
            return downloadURL;
          }
          return null; // Or any placeholder value if not an image
        })
      );
  
      return videoUrls.filter(url => url); // Filter out any null values
  
    } catch (error) {
      console.error('Error getting image URLs:', error);
      // Handle errors appropriately
    }
  }
  const fetchVideos = async () => {
    const urls = await getVideoUrls()
    setVideoUrls(urls);
  };

  fetchVideos();
  },[storage])
  // Function to check file extension (optional)
  function isImage(fileName) {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp']; // Add more extensions if needed
    const extension = fileName.split('.').pop().toLowerCase();

    return allowedExtensions.includes(extension);
  }

    // Function to check file extension (optional)
    function isVideo(fileName) {

      const allowedExtensions = ['mp4', 'mov', 'avi', 'webm', 'wmkv']; // Add more extensions if needed
      const extension = fileName.split('.').pop().toLowerCase();

      return allowedExtensions.includes(extension);
    }

    

   

useEffect(() => {
  const getImagesUrls = async () => {
    try {
      const storageRef = ref(storage, 'gallery'); // Replace with your folder path
      const listResult = await listAll(storageRef);
  
      const imageUrls = await Promise.all(
        listResult.items.map(async (imageRef) => {
        
          // Check if the item is an image (optional)
          if (isImage(imageRef.name)) { // Implement your logic to check image format
            const downloadURL = await getDownloadURL(imageRef);
            
            return downloadURL;
          }
          return null; // Or any placeholder value if not an image
        })
      );
  
      return imageUrls.filter(url => url); // Filter out any null values
  
    } catch (error) {
      console.error('Error getting image URLs:', error);
      // Handle errors appropriately
    }
  }
  const fetchImages = async () => {
    const urls = await getImagesUrls()
    setImageUrls(urls);
  };
  fetchImages();
},[storage])

  useEffect(() => {
    const fetchData = async () => {
    

      try {
        const db = firebase.firestore();
        const colRef = collection(db, 'invites'); // Replace with your collection name
        const q = query(colRef, where('sender', '==', user.uid)) // Build query with filter

        const querySnapshot = await getDocs(q);
        

        const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        setData(data);
      } catch (err) {
        
      } finally {
        
      }
    };

    fetchData();

    
    
  }, [user.uid]);

   
      
  
    const logout = () => {
        auth.signOut();
    };
  
    const handleChange = (event) => {
        setChildData({ ...ChildData, [event.target.name]: event.target.value });
      };

      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
    
        if (!ChildData.name || !ChildData.food) {
          return alert('Please fill in all required fields'); // Handle missing data
        }
    
        try {
          const db = firebase.firestore();
          const colRef = collection(db, 'invites'); // Replace with your collection name
          await addDoc(colRef, ChildData); // Add form data as a document
    
          setChildData({ food: '', name: '' }); // Clear form after submission
          window.location.reload();
         
        } catch (err) {
          console.error('Error adding data:', err);
          alert('An error occurred. Please try again.'); // Inform user about error
        }

       
      };

    
     
    return (
        <div className="img-grid">
             <header className="App-header">
             <center>
                <div
                    style={{
                        display: "block",
                    }}
                >  


      {/* Display data here */}
      {data.length > 0 ? (
        <div>
          {data.map((item) => (
            <p key={item.name}> 
              {item.name} will be having {item.food}  <FontAwesomeIcon icon={faTrash} size={'xs'} onClick={deleteChild} />
            </p>
          ))}
          
          <p>The party will be at Jump Bristol on June 8th @ 10:00-12:30 <br/> 
         
          You can add the event to your calendar <a href="event.ics">here</a>< br/>
          <iframe title="map_to_location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2483591695577!2d-2.5921649234561976!3d51.52700440927113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871919d326e546d%3A0xb3bfeaf958501306!2sJump%20Bristol!5e0!3m2!1sen!2suk!4v1721463275270!5m2!1sen!2suk" width="auto" height="auto"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </p>
        </div>
      ) :  <div>
        <form onSubmit={handleSubmit}>
        <label>Childs Name: </label><input id="name" name="name" className="highlight"  onChange={handleChange} placeholder="Childs Name" value={ChildData.name}></input>
        <label>Food Choice: </label><select id="food" name="food" className="highlight"  onChange={handleChange} value={ChildData.food}>
            <option></option>
            <option>Beef Burger</option>
            <option>Chicken Nuggets</option>
            <option>Veggie Burger</option>
        </select>
        <button  className="highlight">Add Child</button>
        </form>
       </div>
      }
       
        <label>Upload Pictures of Videos for others to see.</label> 
        <div className="flex-container">
      <input type="file" id="uploads" multiple onChange={(event) => {setImages(event.target.files);}} /> <button onClick={uploadFiles}>Submit</button>
<p id="upload state"></p>
</div>
      <div className="flex-container">
       
      {imageUrls.map((url) => (
      <Zoom key={url}>
      <img
       alt=""
       src={url}
       width="200"
      
     />
 </Zoom>
      ))}
    </div>
    {videoUrls.map((url) => (
      <ReactPlayer key={url} url={url} controls={true} />
    ))}
   
                        </div>
                        
                        </center>
                        <br></br>
                        <center>
                        <button className="highlight" style={{ marginLeft: "20px" }} onClick={logout}> Logout</button>
                        </center>
            </header>
        </div>
    );
};
 
export default Mainpage;
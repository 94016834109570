
import React, { useEffect, useState } from "react";
import { firebase, auth, firestore } from "./firebase";

 
const Login = () => {
    // Inputs
    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState("");
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState("");
    const [invited, setInvited] = useState(false);

    useEffect(() => {
        if (mynumber.length === 11) {
            const phoneNumber = `+44${mynumber}`;
          const ref = firestore.collection('parents').doc(phoneNumber);
          ref.get().then(({ exists }) => { setInvited(exists)  });
          
        } else {
          setInvited(false);
        }
      }, [mynumber]);
    // Sent OTP
    const signin = () => {
        
        if (mynumber === "" || mynumber.length < 11) return;
       
           
        let verify = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container"
        );
        auth.signInWithPhoneNumber(`+44${mynumber}`, verify)
            .then((result) => {
                setfinal(result);
               
                setshow(true);
            })
            .catch((err) => {
                alert(err);
                window.location.reload();
            });
    };
 
    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null) return;
        final
            .confirm(otp)
            .then((result) => {
                // success
            })
            .catch((err) => {
                alert("Wrong code");
            });
    };
 
    return (
        <div className="flex-container">
             <header className="App-header">
            <center>
                <div
                    style={{
                        display: !show ? "block" : "none",
                    }}
                >
                    <input
                        value={mynumber}
                        onChange={(e) => {
                            setnumber(e.target.value);
                        }}
                        placeholder="phone number"
                    />
                    <br />
                    <br />
                    {invited ? 
          <p className="success">You are invited 👋</p> : 
          <p className="danger">You are not invited 😞</p>
          
        }  
                    <div id="recaptcha-container"></div>
                    <button className={!invited ? 'hide' : ''} onClick={signin}>
                        Login
                    </button>
                </div>
                <div
                    style={{
                        display: show ? "block" : "none",
                    }}
                >
                    <input
                        type="text"
                        placeholder={"Enter your SMS Code"}
                        onChange={(e) => {
                            setotp(e.target.value);
                        }}
                    ></input>
                    <br />
                    <br />
                    <button onClick={ValidateOtp}>
                        Verify
                    </button>
                </div>
            </center>
            </header>
        </div>
    );
};
 
export default Login;